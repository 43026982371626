<template>
    <div class="grid-header">
        <div class="d-flex align-items-center" v-if="$slots.actions || $slots.default">
            <div class="flex-grow-1">
                <h1 class="mt-0 font-weight-light"><slot></slot></h1>
            </div>
            <div v-if="$slots.actions">
                <slot name="actions"></slot>
            </div>
        </div>
        <div class="d-flex" v-if="showFilters">
            <div class="filters">
                <mercur-input
                    v-if="typeof quickSearch !== 'undefined'"
                    data-test="quickSearchInput"
                    class="mb-0"
                    :clearable="true"
                    v-model="quickSearchLocal"
                >
                    Search
                </mercur-input>
                <slot name="filters"></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'GridHeader',
    props: ['quickSearch'],
    computed: {
        quickSearchLocal: {
            get () {
                return this.quickSearch
            },
            set (value) {
                this.$emit('update:quickSearch', value)
            },
        },
        showFilters () {
            return typeof this.quickSearch !== 'undefined' || this.$slots.filters
        },
    },
}
</script>

<style lang="scss" scoped>
.filters {
    max-width: 600px;
    min-width: 300px;
    align-items: flex-end;
}
.full-width-filters {
    .filters {
        max-width: 100%;
        width: 100%;
    }
}
</style>
