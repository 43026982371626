<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Legacy products
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
            :selected-rows.sync="selectedRows"
            v-if="isAllowedTo('getLegacyProducts')"
        ></data-table>
        <mercur-dialog :is-open.sync="confirmDialogOpen" width="100vw" height="100%">
            <div class="full-height-layout" v-if="confirmDialogProducts">
                <div slot="header">
                    <h2 class="font-weight-normal">S2P generation for {{confirmDialogProducts.length}} product{{confirmDialogProducts.length > 1 ? 's' : ''}}</h2>
                </div>
                <div slot="default">
                    <p>Please select the countries you want to generate the s2p products for</p>
                    <v-select
                        v-if="countryList"
                        v-model="confirmDialogCountries"
                        :options="countryList.filter(country => !confirmDialogCountries.includes(country.countryCode))"
                        label="countryName"
                        :disabled="loading"
                        required
                        multiple
                        placeholder="Countries"
                        :reduce="country => country.countryCode"
                    ></v-select>

                    <div class="turnarounds fill full-height-layout">
                        <label class="turnarounds__label">Turnarounds <a @click="toggleSelectAll">Select all/none</a></label>
                        <div class="fill">
                            <div v-for="(turnaround, key) in availableTurnarounds" :key="key">
                                <mercur-checkbox class="my-3" v-model="confirmDialogTurnarounds" :value="turnaround" :disabled="loading">{{turnaround}}</mercur-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div slot="footer">
                    <mercur-button class="btn btn-yellow text-uppercase" @click="confirmDialogOpen = false" :disabled="loading">Close</mercur-button>
                    <mercur-button class="btn btn-yellow text-uppercase" @click="onConfirm" :disabled="loading || confirmDialogCountries.length === 0">Start</mercur-button>
                </div>
            </div>
            <mercur-progress-bar indeterminate v-if="loading"></mercur-progress-bar>
        </mercur-dialog>
        <portal to="actionbar">
            <transition name="slide">
                <div v-if="selectedRows.length" class="card-padding">
                    <mercur-button class="btn btn-raised btn-yellow text-uppercase" @click="openTriggerConfirm" :disabled="!isAllowedTo('generateS2PCSV')">Generate {{selectedRows.length}} product{{selectedRows.length > 1 ? 's' : ''}}</mercur-button>
                </div>
            </transition>
        </portal>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import vSelect from 'vue-select'
import GridHeader from '@/components/GridHeader'
import DataTable from '@/components/DataTable'

export default {
    name: 'LegacyProductsOverview',
    components: {
        DataTable,
        GridHeader,
        vSelect,
    },
    data () {
        return {
            options: {
                columns: {
                    'Name': {
                        field: 's2pProductName',
                        sortable: true,
                    },
                },
                isSelectable: true,
                quickSearchColumns: ['s2pProductName'],
            },
            url: CONFIG.API.ROUTES.PRODUCTS.LEGACYPRODUCTS.GET,
            filters: {
                search: '',
            },

            loading: false,
            toggleTurnarounds: false,
            confirmDialogOpen: false,
            confirmDialogProducts: null,
            confirmDialogTurnarounds: [],
            confirmDialogCountries: [],
            serverSideDatasource: null,
            selectedRows: [],
            selectedTurnaround: null,
            availableTurnarounds: [
                'Same day',
                'Urgent: 1 day',
                '1 day',
                ...Array.from({ length: 43 }, (v, k) => `${(k + 2)} days`),
            ],
            countryList: [
                {
                    countryName: 'Netherlands',
                    countryCode: 'NL',
                },
                {
                    countryName: 'Belgium',
                    countryCode: 'BE',
                },
                {
                    countryName: 'United Kingdom',
                    countryCode: 'UK',
                },
                {
                    countryName: 'Ireland',
                    countryCode: 'IE',
                },
                {
                    countryName: 'Spain',
                    countryCode: 'ES',
                },
                {
                    countryName: 'Germany',
                    countryCode: 'DE',
                },
                {
                    countryName: 'France',
                    countryCode: 'FR',
                },
                {
                    countryName: 'Italy',
                    countryCode: 'IT',
                },
            ],
        }
    },

    methods: {
        toggleSelectAll () {
            if (this.confirmDialogTurnarounds.length !== this.availableTurnarounds.length) {
                this.confirmDialogTurnarounds = this.availableTurnarounds

                return
            }

            this.confirmDialogTurnarounds = []
        },
        openTriggerConfirm () {
            this.confirmDialogOpen = true
            this.confirmDialogCountries = []
            this.confirmDialogProducts = this.selectedRows.flatMap(product => product.s2pProductName)
        },
        onConfirm () {
            if (!this.isAllowedTo('generateS2PCSV')) {
                return
            }
            const url = CONFIG.API.ROUTES.PRODUCTS.LEGACYPRODUCTS.TRIGGER
            this.addJob(url)
            this.loading = true

            this.$api.post(url, {
                productNames: this.confirmDialogProducts,
                countries: this.confirmDialogCountries,
                turnarounds: this.confirmDialogTurnarounds,
            }).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `S2P generation for ${this.confirmDialogProducts.length} products was scheduled`,
                })
                this.confirmDialogOpen = false
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err.data.exceptions[0].error,
                    type: 'error',
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
    },
}
</script>

<style scoped lang="scss">
.turnarounds {
    &__label {
        color: rgba(0, 0, 0, 0.54);
        font-size: 12px;
    }
}
</style>
